import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import CookieConsent from 'react-cookie-consent';

import { useLocation } from '@reach/router'; // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { getCookie } from 'react-use-cookie';
import { CookiesContainer } from './cookiesBanner.styled';

const isBrowser = () => {
  return typeof window !== 'undefined';
};

const CookiesBanner = () => {
  const [flag, setFlag] = useState(false);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const location = useLocation();
  const cookies = getCookie('gatsby-gdpr-google-analytics');

  if (isBrowser()) {
    initializeAndTrack(location);
  }

  useEffect(() => {
    if (!cookies) {
      setTimeout(() => {
        setIsDisplayed(true);
      }, 1400);
    }
  }, []);

  useEffect(() => {
    if (cookies) setFlag(true);
    else setFlag(false);
  }, []);

  const cookiesHandler = () => {
    document.cookie = 'gatsby-gdpr-google-analytics=true';
    setFlag(true);
  };
  if (!isDisplayed) return null;

  return (
    <CookiesContainer flag={flag}>
      <CookieConsent
        cookieName='gatsby-gdpr-google-analytics'
        onAccept={cookiesHandler}
        ButtonComponent={({ children, ...props }) => (
          <div>
            <button {...props}>{children}</button>
            <Link
              to='/terms-conditions-and-privacy/#privacy'
              className='privacy-cta'
              title='Privacy Policy'
            >
              Privacy Policy
            </Link>
          </div>
        )}
        buttonText='Got it'
        location='bottom'
        buttonWrapperClasses='button-container'
        contentClasses='content-container'
      >
        <p className='cookie-text'>Cookies</p>
        <p className='content'>
          This website uses cookies, some of which are necessary for the
          operation of the website and some of which are designed to improve
          your experience. To find out more about the cookies we use, see our
          privacy policy.
        </p>
      </CookieConsent>
    </CookiesContainer>
  );
};

export default CookiesBanner;
