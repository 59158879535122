import styled from 'styled-components';
import { headLine4, linkText, para2 } from '../../theme/GlobalStyle';

export const FooterContainer = styled.footer`
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > li {
      flex-basis: 23%;
    }
  }
  @media (max-width: 995px) {
    .wrapper > li {
      flex-basis: 48%;
    }
  }
  @media (max-width: 575px) {
    .wrapper > li {
      flex-basis: 100%;
    }
  }
`;

export const FooterTop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  padding: 20px 0 40px 0;
  background-color: ${(props) => props.theme.color.greyBg};
  text-transform: capitalize;

  .main-menu {
    display: block;
    margin: 20px 0 10px 0;
    ${headLine4}
    color: ${(props) => props.theme.color.black};
  }

  .main-menu-hover {
    transition: color ease-in-out 0.2s;
    &:hover {
      color: ${(props) => props.theme.color.bgPrimary};
    }
  }

  .sub-menu {
    li {
      list-style: none;
      margin: 0 0 6px 0;
      padding: 0;

      &:last-of-type {
        margin: 0;
      }
      a {
        ${para2}
        color: ${(props) => props.theme.color.textLight};
        transition: color ease-in-out 0.2s;
        &:hover {
          color: ${(props) => props.theme.color.bgPrimary};
        }
      }
    }
  }
`;

export const FooterBottom = styled.div`
  padding: 30px 0;
  border-top: 1px solid ${(props) => props.theme.color.lightGrey};
`;

export const FooterMessage = styled.div`
  flex-basis: 55%;
  ${linkText}
  color: ${(props) => props.theme.color.textLight};
  a {
    text-decoration: underline;
    transition: color ease-in-out 0.2s;
    @media (min-width: 1024px) {
      &:hover {
        color: ${(props) => props.theme.color.bgPrimary};
      }
    }
  }
  @media (max-width: 824px) {
    flex-basis: 100%;
    text-align: center;
    order: 2;
  }
`;

export const SocialLinks = styled.ul`
  display: flex;
  align-items: center;
  li {
    margin-right: 20px;
    font-size: 20px;
    a {
      color: ${(props) => props.theme.color.primary};
      font-size: 18px;
      ${(props) => props.theme.fontStyleRegular}

      svg {
        path {
          fill: ${(props) => props.theme.color.primary};
        }
      }
    }
  }
  .youtube-icon a {
    display: inline-block;
    padding-top: 3px;
    font-size: 20px;
  }
  @media (max-width: 824px) {
    margin: 5px auto;
    order: 1;
  }
`;
