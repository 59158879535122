import styled, { keyframes } from 'styled-components';
import { headLine3, linkText, readmoreText } from '../../theme/GlobalStyle';

const anim = keyframes`
 0% { opacity: 0; }
 100% { opacity:1; }
 `;

export const CookiesContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 10000003;
  animation-name: ${anim};
  animation-duration: 0.8s;
  .CookieConsent {
    width: 60% !important;
    display: block !important;
    max-width: 640px;
    padding: 30px 40px !important;
    left: unset !important;
    right: 25px !important;
    bottom: 25px !important;
    background-color: ${(props) => props.theme.color.white} !important;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .content-container {
    margin: 0 !important;
  }
  .CookieConsent .button-container {
    text-align: left;
    display: inline-block !important;
  }
  p.cookie-text {
    color: ${(props) => props.theme.color.textLight};
    ${headLine3}
  }
  p.content {
    margin: 20px 0;
    color: ${(props) => props.theme.color.textLight};
    ${linkText}
  }
  .button-container button {
    padding: 10px 15px !important;
    margin: 0 !important;
    border-radius: 6px !important;
    border: 1px solid ${(props) => props.theme.color.primary} !important;
    background-color: ${(props) => props.theme.color.primary} !important;
    color: ${(props) => props.theme.color.white} !important;
    ${readmoreText}
    transition: color ease-in-out 0.2s, background-color ease-in-out 0.2s;
  }

  .button-container button:hover {
    @media (min-width: 1024px) {
      background-color: ${(props) => props.theme.color.white} !important;
      color: ${(props) => props.theme.color.primary} !important;
    }
  }

  .privacy-cta {
    padding: 13px 15px;
    margin: 0 0 0 10px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.color.greyBg};
    color: ${(props) => props.theme.color.textLight};
    ${readmoreText}
  }
  @media (max-width: 768px) {
    .CookieConsent {
      width: calc(100% - 24px) !important;
      right: 50% !important;
      transform: translateX(50%);
      max-width: none;
    }
    .CookieConsent .button-container {
      width: 100%;
    }
  }
`;
