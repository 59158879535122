import styled from 'styled-components';

export const AccordionContainer = styled.div`
  .accordion-title {
    cursor: pointer;
    text-transform: capitalize;
  }
  span.dropdown-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${(props) => (props.NewsLetterFooter ? '0 10px' : null)};
    cursor: pointer;
  }
  span.dropdown-arrow svg {
    color: ${(props) =>
      props.NewsLetterFooter
        ? props.theme.color.primary
        : props.theme.color.white};
    font-size: 22px;
  }
  .collapse-menu {
    display: flex;
    justify-content: ${(props) =>
      props.NewsLetterFooter ? 'flex-start' : 'space-between'};
    align-items: center;
  }
`;
export const Children = styled.div`
  transition: 1s ease-in-out;
  overflow: hidden;
  max-height: 0;
  &.active {
    max-height: 500px;
  }
`;
