export const headerNav = [
  {
    title: 'Our Priorities',
    url: '/policies-and-actions/',
    regex: /policies-and-actions|initiatives/,
    subNav: [
      {
        title: 'COVID-19 Response',
        url: '/policies-and-actions/covid-19-response/#policy-title',
        regex: /policies-and-actions\/covid-19-response/,
      },
      {
        title: 'Pandemic Prevention & Preparedness',
        url: '/policies-and-actions/pandemic-preparedness-prevention/#policy-title',
        regex: /policies-and-actions\/pandemic-preparedness-prevention/,
      },
      {
        title: 'Initiatives',
        url: '/initiatives/',
        regex: /initiatives/,
      },
    ],
  },
  {
    title: 'News',
    url: '/news/',
    regex: /\bnews\b/,
  },
  {
    title: 'Our Network',
    url: '/our-network/',
    regex: /our-network/,
  },
  {
    title: 'Get Involved',
    url: '#',
    regex: /events|our-newsletter|join-our-team|contact-us/,
    subNav: [
      {
        title: 'Events',
        url: '/events/',
        regex: /events/,
      },
      {
        title: 'Newsletter',
        url: '/our-newsletter/',
        regex: /our-newsletter/,
      },
      {
        title: 'Join Our Team',
        url: '/join-our-team/',
        regex: /join-our-team/,
      },
      {
        title: 'Contact Us',
        url: '/contact-us/',
        regex: /contact-us/,
      },
    ],
  },
];

export const footerNav = [
  {
    title: 'Our Priorities',
    url: '/policies-and-actions/',
    subNav: [
      {
        title: 'COVID-19 Response',
        url: '/policies-and-actions/covid-19-response/#policy-title',
      },
      {
        title: 'Pandemic Prevention & Preparedness',
        url: '/policies-and-actions/pandemic-preparedness-prevention/#policy-title',
      },
      {
        title: 'Initiatives',
        url: '/initiatives/',
      },
    ],
  },
  {
    title: 'News',
    url: '/news/',
    subNav: [
      {
        title: 'COVID-19 Response',
        url: '/news/covid-19-response/',
      },
      {
        title: 'Pandemic Prevention & Preparedness',
        url: '/news/pandemic-preparedness-prevention/',
      },
    ],
  },
  {
    title: 'Our Network',
    url: '/our-network/',
    subNav: [
      {
        title: 'Our Mission',
        url: '/our-network/#ourMission',
      },
      {
        title: 'Our Impact',
        url: '/our-network/#ourImpact',
      },
      {
        title: 'Our Partners',
        url: '/our-network/#ourPartners',
      },
      {
        title: 'Our Team',
        url: '/our-network/#ourTeam',
      },
      {
        title: 'Advisory Committee',
        url: '/our-network/#advisory_committee',
      },
    ],
  },
  {
    title: 'Get Involved',
    subNav: [
      {
        title: 'Events',
        url: '/events/',
      },
      {
        title: 'Newsletter',
        url: '/our-newsletter/',
      },
      {
        title: 'Join Our Team',
        url: '/join-our-team/',
      },
      {
        title: 'Contact Us',
        url: '/contact-us/',
      },
      {
        title: 'Terms & Conditions',
        url: '/terms-conditions-and-privacy/',
      },
      {
        title: 'Privacy Policy',
        url: '/terms-conditions-and-privacy/#privacy',
      },
    ],
  },
];
