import $ from 'jquery';

// position changes so that header is visible, when modal is open
// isHeader to check this function is for humburger menu
export const removeOverflow = (isHeader) => {
  if (isHeader) {
    $('html').addClass('hidden-header');
  } else {
    $('html').css('overflow', 'hidden');
    $('header').css('position', 'fixed');
  }
};

export const addOverflow = (isHeader) => {
  if (isHeader) {
    $('html').removeClass('hidden-header');
  }
  $('html').css('overflow', 'visible');
  $('header').css('position', 'sticky');
};

export const closeBtn = () => {
  $('#\\:1\\.container').contents().find('#\\:1\\.close img').click();
};
