import styled from 'styled-components';
import { Link } from 'gatsby';
import { para2, readmoreText } from '../../theme/GlobalStyle';

export const MainHeader = styled.header`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10000002; // increased z-index for bringing header on top of google translater topbar
`;

export const Title = styled.div`
  width: 110px;
  position: absolute;
  z-index: 15;
  top: 9%;
  left: 35px;
  &::after {
    content: '';
    width: 100px;
    height: 43px;
    border-radius: 50% / 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: absolute;
    top: 60%;
    left: 3px;
    background-color: transparent;
    box-shadow: 0 5px 15px rgb(0, 0, 0, 0.15);
    z-index: -10;
  }
  @media (max-width: 1400px) {
    width: 98px;
    top: 0;
    &::after {
      width: 85px;
      top: 55%;
      left: 5px;
    }
  }
  @media (max-width: 1024px) {
    width: 80px;
    top: 18%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    &::after {
      width: 72px;
      height: 35px;
      left: 3px;
    }
  }
  @media (max-width: 995px) {
    top: 32%;
  }
  @media (max-width: 768px) {
    top: 30%;
  }
`;

export const HeaderContainer = styled.nav`
  height: 70.28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 15px rgb(0, 0, 0, 0.15);
  position: relative;
  background-color: ${(props) => props.theme.color.white};
  color: ${(props) => props.theme.color.primary};
  .wrapper {
    max-width: 1600px;
    width: 92%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .bar {
      text-indent: -99999px;
      float: left;
    }
    .hamburger {
      width: 25px;
      height: 18px;
      display: none;
      padding-left: 10px;
      position: relative;
      color: ${(props) => props.theme.color.primary};
      cursor: pointer;
    }
    .hamburger::before,
    .hamburger::after {
      content: '';
      width: 25px;
      height: 2px;
      position: absolute;
      top: ${(props) => (props.expanded ? '7px' : '0')};
      background-color: #000;
      -webkit-transition: 0.4s ease;
      -o-transition: 0.4s ease;
      transition: 0.4s ease;
    }
    .hamburger::after {
      -webkit-transform: ${(props) =>
        props.expanded ? 'rotate(45deg)' : 'translateY(0)'};
      -ms-transform: ${(props) =>
        props.expanded ? 'rotate(45deg)' : 'translateY(0)'};
      transform: ${(props) =>
        props.expanded ? 'rotate(45deg)' : 'translateY(0)'};
      -webkit-box-shadow: ${(props) => (props.expanded ? '' : '0 7px 0 #000')};
      box-shadow: ${(props) => (props.expanded ? '' : '0 7px 0 #000')};
    }
    .hamburger::before {
      -webkit-transform: ${(props) =>
        props.expanded ? 'rotate(-45deg)' : 'translateY(14px)'};
      -ms-transform: ${(props) =>
        props.expanded ? 'rotate(-45deg)' : 'translateY(14px)'};
      transform: ${(props) =>
        props.expanded ? 'rotate(-45deg)' : 'translateY(14px)'};
      -webkit-transition: 0.6s ease;
      -o-transition: 0.6s ease;
      transition: 0.6s ease;
    }

    .youtube-icon svg {
      font-size: 20px;
    }
    @media (max-width: 1024px) {
      justify-content: space-between;

      .header-expanded {
        display: none;
      }
      .hamburger {
        display: block;
      }
    }
    .header-expanded {
      width: 61%;
      max-width: 900px;
      margin-right: 3%;

      @media (max-width: 1280px) {
        margin-right: 0;
      }
    }
    .translate-wrapper {
      width: 100%;
      max-width: 220px;
      position: relative;
      @media (max-width: 1024px) {
        display: none;
      }
    }

    #google_translate_element {
      height: 43px !important;
      select {
        height: 43px !important;
        padding-top: 1px !important;
        padding-bottom: 1px !important;
      }
    }

    @media (max-width: 768px) {
      width: 88%;
    }
    @media (max-width: 480px) {
      width: 95%;
    }
  }
  &.expanded {
    overflow: visible;
  }

  @media (max-width: 1400px) {
    padding: 15px 0 12px 0;
  }
  @media (max-width: 1024px) {
    position: relative;
  }
  @media (max-width: 995px) {
    height: 84px;
    padding: 20px 0 20px 0;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const MobileMenu = styled.div`
  height: 100vh;
  padding: 0;
  transform: translateY(-100%);
  pointer-events: none;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: ${(props) => props.theme.color.primary};
  transition: 1s ease-in-out;
  .wrapper {
    padding-bottom: 30px;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    max-height: 90vh;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 10px;
    }
    .top {
      margin: 15px 0;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      color: ${(props) => props.theme.color.white};
      span {
        align-self: center;
        ${(props) => props.theme.fontStyleRegular}
        font-size: 20px;
        font-weight: 400;
      }
    }

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  .mobile-links {
    width: 100%;
    .accordion-title {
      color: ${(props) => props.theme.color.white};
      text-transform: capitalize;
    }
    li {
      &:nth-child(2),
      &:nth-child(3) {
        padding: 0 45px;
        border-bottom: 1px solid rgba(255, 255, 255, 30%);
      }
      &:nth-child(2) a,
      &:nth-child(3) a {
        padding: 20px 0;
        display: block;
      }
      div.collapse-menu {
        padding: 20px 45px !important;
        border-bottom: 1px solid rgba(255, 255, 255, 30%);

        a {
          width: 85%;
        }

        .dropdown-arrow {
          width: 20%;
          justify-content: flex-end;
        }

        @media (max-width: 480px) {
          padding: 20px !important;
        }
      }

      &:first-child {
        border-top: none;
      }
      a,
      p.accordion-title {
        ${(props) => props.theme.fontStyleMedium}
        font-size: 16px;
      }
      .submenu {
        background-color: ${(props) => props.theme.color.darkBlue};
        li {
          &:first-child,
          &:last-child {
            border-bottom: 1px solid rgba(255, 255, 255, 30%);
          }
          border-top: none;
          padding-left: 15px;
          a {
            padding: 20px 0 20px 60px;
            display: block;
            font-size: 16px;
            ${(props) => props.theme.fontStyleRegular}
            font-weight: 300;
          }

          @media (max-width: 480px) {
            a {
              padding: 20px 0 20px 20px;
            }
          }
        }
      }

      @media (max-width: 480px) {
        &:nth-child(2),
        &:nth-child(3) {
          padding: 0 20px;
        }
      }
    }
    a {
      color: ${(props) => props.theme.color.white};
      text-transform: capitalize;
      &.active {
        opacity: 0.6;
      }
    }
  }
  .social-links {
    margin: auto;
    width: 100%;
    display: flex;
    li {
      margin: 10px 20px 0 0;
    }
    a {
      color: ${(props) => props.theme.color.white};
      text-transform: capitalize;

      svg {
        path {
          fill: ${(props) => props.theme.color.white};
        }
      }
    }

    @media (max-width: 1024px) {
      width: 90%;
      margin: 0 auto;
      padding-bottom: 160px;
    }
  }
  &.expanded {
    opacity: 1;
    display: none;
    pointer-events: auto;
    transform: translateY(0);

    @media (max-width: 1024px) {
      margin-top: ${(props) =>
        props.selectLangVal === 'en' ? '70px' : '115px'};
      display: block;
    }
  }
`;

export const HeaderLinks = styled.ul`
  display: flex;
  flex-grow: 1;
  li {
    flex-grow: 1;
    ${para2}
    a {
      display: inline-flex;
      align-items: center;
      color: ${(props) => props.theme.color.textLightSecond};
      text-transform: capitalize;
      transition: color ease-in-out 0.2s;
      &.active {
        color: ${(props) => props.theme.color.primary};
        ${readmoreText}
      }

      & svg {
        margin-left: 10px;
        color: ${(props) => props.theme.color.primary};
        font-size: 22px;
        transition: transform 100ms;

        @media (max-width: 1280px) {
          margin-left: 5px;
        }
      }
      &:hover {
        color: ${(props) => props.theme.color.primary};
      }
    }
    &.dropdown {
      position: relative;
      z-index: 20;
      .dropdown-content {
        max-height: 0;
        padding: 10px;
        position: absolute;
        top: 100%;
        left: -5%;
        background-color: ${(props) => props.theme.color.white};
        color: ${(props) => props.theme.color.primary};
        pointer-events: none;
        overflow: hidden;
        transition: all ease-in-out 0.3s;
        a {
          width: 100%;
          display: inline-block;
          margin: 5px;
          white-space: nowrap;
          transition: opacity ease-in-out 0.2s;

          &.active {
            pointer-events: none;
          }
        }

        li {
          cursor: pointer;
        }
      }
      &:hover {
        & a svg {
          transform: rotateZ(180deg);
        }

        .dropdown-content {
          max-height: 500px;
          box-shadow: 0 5px 15px rgb(0, 0, 0, 0.15);
          pointer-events: auto;
        }
      }
    }
    &.dropdown.active > a {
      color: ${(props) => props.theme.color.primary};
    }
  }
  @media (max-width: 1400px) {
    li {
      a {
        padding: 0;
      }
    }
  }
`;

export const SocialLinks = styled.ul`
  display: flex;
  align-items: center;
  flex-grow: 0.4;
  li {
    flex-grow: 1;
    font-size: 12px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: ${(props) => props.theme.color.primary};
      text-transform: uppercase;

      svg {
        path {
          fill: ${(props) => props.theme.color.primary};
        }
      }
    }
  }
`;

export const Search = styled(Link)`
  max-width: 35px;
  padding: 8px;
  margin: 0 0 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: ${(props) => props.theme.color.lightGrey};
  font-size: 14px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  svg {
    font-size: 19px;
    path {
      fill: ${(props) => props.theme.color.black};
    }
  }
  .search-input {
    margin-right: 108px;
    color: ${(props) => props.theme.color.textLightSecond};
  }
  &.disable {
    pointer-events: none;
  }
  @media (min-width: 1024px) {
    width: 4%;

    &:hover {
      opacity: 0.5;
    }
  }
  @media (max-width: 995px) {
    border: none;
    margin: 0;
    .search-input {
      display: none;
    }
    svg {
      font-size: 22px;
    }
  }
`;

export const SearchBar = styled(Link)`
  padding: 10px;
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.color.white};
  span {
    display: block;
    color: ${(props) => props.theme.color.primary};
    ${(props) => props.theme.fontStyleLight};
  }
  svg {
    font-size: 20px;
    path {
      stroke: ${(props) => props.theme.color.white};
    }
  }
`;

export const CloseButton = styled.button`
  border: none;
  position: absolute;
  left: 0;
  top: 4px;
  font-size: 20px;
  background-color: transparent;
  svg {
    path {
      stroke: ${(props) => props.theme.color.white};
    }
  }
  cursor: pointer;
`;

export const CustomClose = styled.div`
  position: fixed;
  width: 15px;
  height: 15px;
  top: 12px;
  right: 10px;
  background-color: transparent;
  opacity: 0;
`;
