import styled, { keyframes } from 'styled-components';
import { para1, para2 } from '../../theme/GlobalStyle';

const changeBackground = keyframes`
  0%{
    transform: scaleX(0);
  }
  100%{
    transform: scaleX(1);
  }
`;

export const NewsLetterContainer = styled.div`
  height: auto;
  padding: 20px 0 18px;
  min-height: 66px;
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.white};
  ${para1}
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  .wrapper {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  div {
    position: relative;
    a {
      padding: 0 5px;
      position: relative;
      z-index: 5;
      display: inline-block;
    }
  }
  .newsLetter {
    position: relative;
    color: ${(props) => props.theme.color.white};
    transition: color 150ms ease-in-out;

    @media (min-width: 1024px) {
      &:hover {
        color: ${(props) => props.theme.color.textLightSecond};
        &:after {
          background-color: ${(props) => props.theme.color.orangeUnderline};
        }
      }
    }
  }
  .newsLetter:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transform: ${(props) => (props.animate ? 'scaleX(0)' : 'scaleX(1)')};
    transform-origin: left;
    background-color: ${(props) => props.theme.color.passion};
    animation: 2s ${(props) => props.animate && changeBackground};
    animation-delay: 0.9s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  @media (max-width: 1400px) {
    ${para2}
  }
  @media (max-width: 768px) {    
    .wrapper {
      flex-direction: column;
    }
  }
  @media (max-width: 576px) {
    min-height: 89px;
  }
`;
