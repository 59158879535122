import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link } from 'gatsby';
import { IoLogoLinkedin } from 'react-icons/io5';
import { BsInstagram, BsSearch } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { IoLogoYoutube } from 'react-icons/io';
import { FiChevronDown } from 'react-icons/fi';
import { useLocation } from '@reach/router';

import Bluesky from '../../assets/svg/bluesky.svg';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import { removeOverflow, addOverflow, closeBtn } from '../../utils/overflow';

import Accordion from '../Accordion';
import GoogleTranslate from '../GoogleTranslate';

import {
  HeaderContainer,
  HeaderLinks,
  SocialLinks,
  HeaderRight,
  Search,
  MobileMenu,
  CustomClose,
} from './Header.styled';
import { headerNav } from '../../utils/site-config';
import UnitedStatesFlag from '../../assets/svg/Flag_of_United_States.svg';

const HeaderNav = () => {
  const { width } = useWindowDimensions();
  const [expanded, setExpanded] = useState(false);
  const mobileLinkRef = useRef(null);
  const logoRef = useRef(null);
  const searchRef = useRef(null);
  const languageRef = useRef(null);
  const socialLinkRef = useRef(null);
  const languageElem = languageRef.current?.querySelector('select');
  const { pathname } = useLocation();
  const [selectLangVal, setSelectLangVal] = useState('en');
  const [selectFlag, setSelectFlag] = useState(UnitedStatesFlag);

  const mobileMenusHandleToggle = useMemo(() => {
    if (pathname) {
      setExpanded(false);
    }
  }, [pathname]);

  const handleToggle = () => {
    setExpanded(false);
  };

  const closeTranslation = () => {
    closeBtn();
    setSelectLangVal('en');
    setSelectFlag(UnitedStatesFlag);
  };

  const mobileMenuItemshandler = () => {
    if (mobileLinkRef) {
      mobileLinkRef.current?.querySelectorAll('a')?.forEach((anchor) => {
        const href = anchor.getAttribute('href');
        if (href === '/#') return null;
        anchor.addEventListener('click', mobileMenusHandleToggle);
      });
    }
    if (socialLinkRef) {
      socialLinkRef.current?.querySelectorAll('a')?.forEach((anchor) => {
        anchor.addEventListener('click', handleToggle);
      });
    }
    if (logoRef) {
      logoRef.current?.addEventListener('click', mobileMenusHandleToggle);
    }
    if (searchRef) {
      searchRef.current?.addEventListener('click', mobileMenusHandleToggle);
    }
    if (languageRef) {
      languageRef.current
        ?.querySelector('select')
        ?.addEventListener('change', handleToggle);
    }
  };

  const handleSearchClick = (e) => {
    if (pathname.includes('search')) {
      e.preventDefault();
      const searchInput = document.querySelector('#search-bar');
      searchInput?.focus();
    }
  };

  useEffect(() => {
    mobileMenuItemshandler();
  }, []);

  useEffect(() => {
    if (languageElem) {
      languageElem?.addEventListener('change', handleToggle);
    }
  }, [languageElem]);

  useEffect(() => {
    if (expanded) {
      removeOverflow(true);
    } else {
      addOverflow(true);
    }
  }, [expanded]);

  useEffect(() => {
    const dropdownContent = document.querySelectorAll('.dropdown-content');
    dropdownContent.forEach((element) => {
      element.addEventListener('mousemove', () => {
        element.parentElement.classList.add('active');
      });
      element.addEventListener('mouseleave', () => {
        element.parentElement.classList.remove('active');
      });
    });
  }, []);

  return (
    <>
      <CustomClose onClick={closeTranslation} />
      <HeaderContainer
        className={`${expanded ? 'expanded' : ''} header`}
        expanded={expanded}
      >
        <div className='wrapper'>
          <div
            className='hamburger desktop-none'
            onClick={() => setExpanded(!expanded)}
            data-testid='hamburger'
            role='button'
            tabIndex='0'
            onKeyDown={null}
          >
            <span className='bar'>bar</span>
          </div>
          <div className='header-expanded '>
            <HeaderRight>
              <HeaderLinks>
                {headerNav.map((item) =>
                  Array.isArray(item.subNav) && item.subNav.length > 0 ? (
                    <li className='dropdown'>
                      <Link
                        to={item.url}
                        className={item.regex.test(pathname) ? 'active' : null}
                        title={item.title}
                      >
                        {item.title}
                        <FiChevronDown />
                      </Link>
                      <ul className='dropdown-content'>
                        {item.subNav.map((sub) => (
                          <li>
                            <Link
                              to={sub.url}
                              className={
                                sub.regex.test(pathname) ? 'active' : null
                              }
                              title={sub.title}
                            >
                              {sub.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to={item.url}
                        className={item.regex.test(pathname) ? 'active' : null}
                        title={item.title}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ),
                )}
              </HeaderLinks>
              <SocialLinks className='social-icon'>
                <li>
                  <a
                    href='https://www.linkedin.com/company/pandemicactionnetwork/'
                    target='_blank'
                    title='LinkedIn'
                    rel='noreferrer'
                  >
                    {' '}
                    <IoLogoLinkedin />
                  </a>
                </li>
                <li>
                  <a
                    href='https://twitter.com/PandemicAction'
                    target='_blank'
                    title='Twitter'
                    rel='noreferrer'
                  >
                    <FaXTwitter />
                  </a>
                </li>
                <li>
                  <a
                    href='https://bsky.app/profile/panaction.bsky.social'
                    target='_blank'
                    title='Bluesky'
                    rel='noreferrer'
                  >
                    {' '}
                    <Bluesky width={18} height={18} />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.facebook.com/PandemicActionNetwork/'
                    target='_blank'
                    title='Facebook'
                    rel='noreferrer'
                  >
                    {' '}
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.instagram.com/pandemicactionnetwork/'
                    target='_blank'
                    title='Instagram'
                    rel='noreferrer'
                  >
                    {' '}
                    <BsInstagram />
                  </a>
                </li>
                <li className='youtube-icon'>
                  <a
                    href='https://www.youtube.com/channel/UC7ivf0adjuDYtNfHfVqZ3Kw'
                    target='_blank'
                    title='YouTube'
                    rel='noreferrer'
                  >
                    {' '}
                    <IoLogoYoutube />
                  </a>
                </li>
              </SocialLinks>
            </HeaderRight>
          </div>
          <Search
            className='search-field'
            to='/search/'
            title='Search'
            onClick={handleSearchClick}
          >
            <BsSearch />
          </Search>
          <div className='translate-wrapper'>
            {width > 1024 ? (
              <GoogleTranslate
                selectFlag={selectFlag}
                selectLangVal={selectLangVal}
                setSelectFlag={setSelectFlag}
                setSelectLangVal={setSelectLangVal}
              />
            ) : null}
          </div>
        </div>
        <MobileMenu
          className={`${expanded ? 'expanded' : ''}`}
          data-testid='mobile-menu'
          selectLangVal={selectLangVal}
        >
          <div className='wrapper'>
            <div className='top'></div>
            <ul className='mobile-links' ref={mobileLinkRef}>
              {headerNav.map((item) =>
                Array.isArray(item.subNav) && item.subNav.length > 0 ? (
                  <li>
                    <Accordion title={item.title} linkPath={item.url}>
                      <ul className='submenu'>
                        {item.subNav.map((sub) => (
                          <li>
                            <Link
                              to={sub.url}
                              className={
                                sub.regex.test(pathname) ? 'active' : null
                              }
                              title={sub.title}
                            >
                              {sub.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Accordion>
                  </li>
                ) : (
                  <li>
                    <Link
                      to={item.url}
                      className={item.regex.test(pathname) ? 'active' : null}
                      title={item.title}
                    >
                      {item.title}
                    </Link>
                  </li>
                ),
              )}
            </ul>
            {width <= 1024 ? (
              <GoogleTranslate
                languageRef={languageRef}
                selectFlag={selectFlag}
                selectLangVal={selectLangVal}
                setSelectFlag={setSelectFlag}
                setSelectLangVal={setSelectLangVal}
              />
            ) : null}
            <ul className='social-links social-icon' ref={socialLinkRef}>
              <li>
                <a
                  href='https://www.linkedin.com/company/pandemicactionnetwork/'
                  target='_blank'
                  title='Linkedin'
                  rel='noreferrer'
                >
                  {' '}
                  <IoLogoLinkedin />
                </a>
              </li>
              <li>
                <a
                  href='https://twitter.com/PandemicAction'
                  target='_blank'
                  title='Twitter'
                  rel='noreferrer'
                >
                  <FaXTwitter />
                </a>
              </li>
              <li>
                <a
                  href='https://bsky.app/profile/panaction.bsky.social'
                  target='_blank'
                  title='Bluesky'
                  rel='noreferrer'
                >
                  {' '}
                  <Bluesky width={18} height={18} />
                </a>
              </li>
              <li>
                <a
                  href='https://www.facebook.com/PandemicActionNetwork/'
                  target='_blank'
                  title='Facebook'
                  rel='noreferrer'
                >
                  {' '}
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a
                  href='https://www.instagram.com/pandemicactionnetwork/'
                  target='_blank'
                  title='Instagram'
                  rel='noreferrer'
                >
                  {' '}
                  <BsInstagram />
                </a>
              </li>
              <li className='youtube-icon'>
                <a
                  href='https://www.youtube.com/channel/UC7ivf0adjuDYtNfHfVqZ3Kw'
                  target='_blank'
                  title='YouTube'
                  rel='noreferrer'
                >
                  {' '}
                  <IoLogoYoutube />
                </a>
              </li>
            </ul>
          </div>
        </MobileMenu>
      </HeaderContainer>
    </>
  );
};

export default HeaderNav;
