import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../theme';
import GlobalStyles from '../../theme/GlobalStyle';
import CookiesBanner from '../CookiesBanner';
import ScrollUp from '../ScrollTop';
import Header from '../Header';
import Footer from '../Footer';
import NewsLetterBanner from '../NewsLetterBanner';

const Layout = ({ children, location }) => {
  const { pathname } = location;
  const excludePages = ['our-newsletter', 'terms-conditions-and-privacy'];
  const exclude = pathname && excludePages.includes(pathname.replaceAll('/', ''));

  const data = useStaticQuery(graphql`
    query SignUpQuery {
      allWpPage(filter: {slug: {eq: "home"}}) {
        edges {
          node {
            homePageFields {
              signUpBanner {
                signUpFormUrl {
                  target
                  title
                  url
                }
                signUpText
              }
            }
          }
        }
      }
    }
  `);

  const signUptext = get(data, 'allWpPage.edges[0].node.homePageFields.signUpBanner.signUpText');
  const signUpCta = get(data, 'allWpPage.edges[0].node.homePageFields.signUpBanner.signUpFormUrl');

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Header />
      <CookiesBanner />
      <main>
        {children}
        {!exclude && <NewsLetterBanner signUptext={signUptext} signUpCta={signUpCta} id='footer-news-letter' />}
      </main>
      <ScrollUp />
      <Footer />
    </ThemeProvider>
  );
};

export default Layout;
