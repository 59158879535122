import { Link } from 'gatsby';
import React, { useRef, useState, useEffect } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useLocation } from '@reach/router';

import { AccordionContainer, Children } from './Accordion.styled';

const Accordion = ({
  children,
  title = 'Accordion',
  linkPath,
  NewsLetterFooter,
}) => {
  const [show, setShow] = useState(false);
  const activeLink = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (activeLink) {
      const href = activeLink.current.getAttribute('href');
      if (
        href === '/policies-and-actions' &&
        pathname === '/policies-and-actions'
      ) {
        activeLink.current.classList.add('active');
      } else {
        activeLink.current.classList.remove('active');
      }
    }
  }, [pathname]);

  return (
    <AccordionContainer
      data-testid='accordionContainer'
      NewsLetterFooter={NewsLetterFooter}
    >
      <div className={`collapse-menu ${show ? 'active' : null}`}>
        <Link to={linkPath} ref={activeLink} title={title}>
          {title}
        </Link>
        <span
          data-testid='acrdnBtn'
          onClick={(e) => setShow(!show)}
          className='dropdown-arrow'
        >
          {show ? <FiChevronUp /> : <FiChevronDown />}
        </span>
      </div>
      <Children
        data-testid='acrdnChildren'
        className={`${show ? 'active' : ''}`}
      >
        {children}
      </Children>
    </AccordionContainer>
  );
};

export default Accordion;
