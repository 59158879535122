import React from 'react';
import { slideInLeft, slideInRight } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import useIsClient from '../../hooks/useIsClient';
import { NewsLetterContainer } from './Newsletter.banner.styled';

const leftAnimation = keyframes`${slideInLeft}`;
const rightAnimation = keyframes`${slideInRight}`;

const RightSlide = styled.div`
  animation: 1s ${(props) => props.animate && leftAnimation};
  margin-right: 5px;
`;

const LeftSlide = styled.div`
  animation: 1s ${(props) => props.animate && rightAnimation};
`;

const BannerContent = ({ signUptext, signUpCta, animate }) => {
  return (
    <>
      {signUptext && <RightSlide animate={animate}>{signUptext}</RightSlide>}
      {signUpCta?.url && signUpCta?.title
        && (
          <LeftSlide animate={animate}>
            <a
              href={signUpCta?.url}
              target='_blank'
              title={signUpCta?.title}
              className='newsLetter'
              rel='noreferrer'
            >
              {signUpCta?.title}
            </a>
          </LeftSlide>
        )}
    </>
  );
};

const NewsLetterBanner = ({ signUptext, signUpCta, id, homePage }) => {
  const { isClient, key } = useIsClient();

  return (
    <NewsLetterContainer id={id} data-testid='newsletter-banner' animate={homePage}>
      <div className='wrapper' key={key}>
        {homePage && isClient && (
          <BannerContent animate signUptext={signUptext} signUpCta={signUpCta} />
        )}
        {
         !homePage && <BannerContent signUptext={signUptext} signUpCta={signUpCta} />
        }
      </div>
    </NewsLetterContainer>
  );
};

export default NewsLetterBanner;
