import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import HeaderNav from './HeaderNav';
import { Title, MainHeader } from './Header.styled';

const Header = () => {
  return (
    <MainHeader data-testid='main-header'>
      <Title className='header-logo'>
        <Link to='/' title='Pandemic Action Network'>
          <StaticImage
            src='../../images/PANLogo.png'
            alt='Pandemic'
            width={110}
            height={106}
            loading='eager'
          />
        </Link>
      </Title>
      <HeaderNav />
    </MainHeader>
  );
};

export default Header;
