import React, { useEffect, useState } from 'react';
import { IoIosArrowUp } from 'react-icons/io';
import { ScrollToTop } from './scrollTop.styled';

const ScrollUp = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const scrollTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const documentHeight = document.body.scrollHeight;
      const height = window.innerHeight;
      const currentScroll = window.scrollY + height;

      const hasScrolled = window.scrollY + 500 > height;
      setIsVisible(hasScrolled);

      const hasReachedBottom = currentScroll + 100 > documentHeight;
      setIsBottom(hasReachedBottom);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ScrollToTop
      href='#'
      id='back-to-top'
      title='Back to top'
      onClick={scrollTop}
      isBottom={isBottom}
      className={`${isVisible ? null : 'hidden'}`}
    >
      <IoIosArrowUp />
    </ScrollToTop>
  );
};

export default ScrollUp;
