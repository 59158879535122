import styled from 'styled-components';
import { linkText } from '../../theme/GlobalStyle';

export const LangContainer = styled.div`
  width: 100%;

  @media (max-width: 1024px) {
    width: 90%;
    margin: 20px auto;
  }
`;

export const TranslateContainer = styled.div`
  display: none;
  width: 100%;
  max-width: 1024px;
  @media (min-width: 1024px) {
    max-width: 220px;
  }

  .goog-logo-link {
    display: none;
  }
`;

export const ListContainer = styled.ul`
  width: 100%;
  position: absolute;
  max-height: ${(props) => (props.isListVisible ? '500px' : 0)};
  background-color: ${(props) => props.theme.color.white};
  border-radius: 5px;
  box-shadow: 0 5px 15px rgb(0, 0, 0, 0.15);
  margin-left: 10px;
  color: ${(props) => props.theme.color.textLight};
  ${linkText}
  transition: all 0.3s ease-in-out 0s;
  overflow: hidden;

  @media (max-width: 1024px) {
    position: static;
    margin: 0;
  }
  a {
    width: 100%;
    padding: 6px 7px 6px 10px;
    display: block;
    ${linkText}
    transition: all ease 0.3s;
    @media (min-width: 1024px) {
      &:hover {
        color: ${(props) => props.theme.color.black};
      }
    }
  }

  li:first-child a {
    padding-top: 10px;
  }

  li:last-child a {
    padding-bottom: 10px;
  }

  svg {
    width: 30px;
    height: 20px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
  }
`;

export const SelectedLanguage = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
  }

  a {
    width: 100%;
    padding: 10px;
    border: 1px solid ${(props) => props.theme.color.lightGrey};
    border-radius: 6px;
    outline: 0;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    ${linkText}
    text-transform: capitalize;
    color: ${(props) => props.theme.color.textLight};
    background-color: ${(props) => props.theme.color.white};

    .flag-ctn {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media (max-width: 1024px) {
      margin-left: 0;
    }
  }

  svg {
    width: 30px;
    height: 20px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
  }

  .down-arrow {
    vertical-align: middle;
    margin-right: 0;
    width: auto;
    height: auto;
    font-size: 20px;
    transition: transform 0.2s ease-in-out;
  }

  .rotate180 {
    transform: rotate(180deg);
  }
`;
