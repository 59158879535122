import styled from 'styled-components';

export const ScrollToTop = styled.a`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: fixed;
  right: 2%;
  bottom: 3%;
  background-color: rgba(128,129,131, 0.6);
  z-index: 10;
  transition: background-color 0.15s ease-in-out, transform 0.15s ease-in-out;

  svg {
    margin: -2px 0 0;
    color: ${(props) => props.theme.color.white};
    font-size: 28px; 
  }

  @media (max-width: 768px) {
    transform: ${(props) => (props.isBottom ? 'translateY(-40px)' : 'translateY(0)')};
  }

  @media (max-width: 680px) {
    bottom: 50px;
    transform: ${(props) => (props.isBottom ? 'translateY(-45px)' : 'translateY(0)')};
  }

  @media (max-width: 450px) {
     transform: ${(props) => (props.isBottom ? 'translateY(-70px)' : 'translateY(0)')};
  }

  @media (min-width: 1024px) {
    &:hover {
      background-color: rgba(128,129,131,1)
    }
  }

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }
`;
